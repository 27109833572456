import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  // Step 1: Initialize state for currency
  const { i18n } = useTranslation(); // Access i18n instance
  const [language, setLanguage] = useState('en_EN'); // Default to USD

  // Step 2: Retrieve the currency value from localStorage if available
  useEffect(() => {
    const storedLanguage = localStorage.getItem('pxlLanguage');
    if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []); // Empty dependency array to run this effect only once, during component initialization

  // Function to handle currency change
  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;

    // Update state and localStorage
    setLanguage(newLanguage);
    localStorage.setItem('pxlLanguage', newLanguage);
    i18n.changeLanguage(newLanguage);
    
 //const currentPath = window.location.href + window.location.search;
//const newPath = currentPath.replace(/^\/(en|fr)(\/|$)/, `/${newLanguage}$2`);

//window.location.href = window.location.origin + newPath;




    const metastarkAddress = localStorage.getItem('walletAddress');
    const metastarkStarkey = localStorage.getItem('walletStark');
    const metastarkNetwork = localStorage.getItem('walletNetwork');
    const metastarkEmail = localStorage.getItem('walletEmail');
    const metastarkProvider = localStorage.getItem('walletPreference');
    const metaCurrency = localStorage.getItem('pxlCurrency');
    const metaBalance = localStorage.getItem('pxlBalance');
    const metaLanguage = localStorage.getItem('pxlLanguage');

    window.postMessage(
      {
        type: 'UPDATE_WALLET_ADDRESS',
        walletAddress: metastarkAddress,
        walletNetwork: metastarkNetwork,
        walletStark: metastarkStarkey,
        walletEmail: metastarkEmail || '',
        walletPreference: metastarkProvider,
        pxlCurrency: metaCurrency,
        pxlLanguage: metaLanguage,
      },
      window.location.origin // Only send the message to the same origin
    );
    
    
    window.location.reload();


  };

  return (
    <span>
      <select value={language} onChange={handleLanguageChange} className="currency-selector">
    <option value="ar">Arabic</option>
    <option value="cn">Chinese</option>
    <option value="nl">Dutch</option>
    <option value="en">English</option>
    <option value="fr">French</option>
    <option value="de">German</option>
    <option value="he">Hebrew</option>
    <option value="it">Italian</option>
    <option value="jp">Japanese</option>
    <option value="kr">Korean</option>
    <option value="pt">Portuguese</option>
    <option value="ru">Russian</option>
    <option value="sp">Spanish</option>
      </select>
    </span>
  );
}

export default LanguageSelector;












